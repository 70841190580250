<template>
    <div>
        <b-card no-body>
            <!-- create -->
            <div v-if="currentUser.roles[0].name !== ('admin' && 'tec_client')" class="p-1">
                <div class="d-flex align-items-center justify-content-end">
                    <b-link :to="{ name: 'addIncidence', params: { asset_id: this.asset_id } }">
                        <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                            {{ '+ Registrar Incidencia' }}
                        </b-button>
                    </b-link>
                </div>
            </div>
            <hr class="m-0" />
            <!-- filters -->

            <!-- tabla -->
            <b-table hover responsive :items="incidences" :fields="columns" class="table-white">

                <template #cell(status)="data">
                    <b-badge class="px-2" :variant="statusIncidenceVariants[data.item.apto]" style="top: -3px">
                        {{ statusIncidenceTexts[data.item.apto] }}
                    </b-badge>
                </template>
                <template #cell(actions)="data">
                    <span>
                        <b-link :to="{ name: 'viewIncidence', params: { id: data.item.id } }"
                            class="d-inline-block px-50 text-dark">
                            <feather-icon icon="EyeIcon" />
                        </b-link>
                        <span v-if="currentUser.roles[0].name === 'super_admin' || currentUser.id == data.item.user.id">
                            <b-link v-if="data.item.apto === 0"
                                :to="{ name: 'editIncidence', params: { id: data.item.id } }"
                                class="d-inline-block px-50 text-dark">
                                <feather-icon icon="Edit2Icon" />
                            </b-link>
                        </span>

                    </span>
                </template>
            </b-table>
            <!-- paginador  -->
            <div class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2" v-if="totalItems > pageLength">
                <div class="d-flex align-items-center mb-0">
                    <span class="text-nowrap ">
                        {{ 'Por página' }}:
                    </span>
                    <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
                    <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
                </div>
                <div>
                    <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number
                        last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                        @change="handleChangePage">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
    BCard,
    BTable,
    BLink,
    BButton,
    BCol,
    BRow,
    BFormSelect,
    BPagination,
    BBadge,
    BFormGroup
} from "bootstrap-vue";
import { config } from "@/shared/app.config"
import store from "@/store/index";
import vSelect from "vue-select";
import incidences from "@/store/incidences";

export default {
    components: {
        BTable,
        BCard,
        BLink,
        BCol,
        BRow,
        BButton,
        BFormSelect,
        BPagination,
        BFormGroup,
        BBadge,
        vSelect
    },
    props: {
        client_id: {
            type: Number,
            default: 0,
        },
        project_id: {
            type: Number,
            default: 0,
        },
        asset_id: {
            type: Number,
            default: 0,
        },

    },
    data() {
        return {
            currentPage: 1,
            pageLength: 10,
            pages: ['10', '15', '25'],
            searchTerm: '',
            columns: [
                {
                    label: 'Ref',
                    key: 'ref',
                },
                {
                    label: 'Nombre',
                    key: 'name',
                },
                {
                    label: 'Técnico',
                    key: 'user.name',
                },
                {
                    label: "Estado",
                    key: "status",
                },
                {
                    label: 'Acciones',
                    key: 'actions',
                    class: 'column-actions',
                }
            ],
            statusIncidenceVariants: config.statusIncidenceVariants,
            statusIncidenceTexts: config.statusIncidenceTexts
        };
    },
    computed: {
        ...mapGetters({
            // client: "clients/getClient",
            currentUser: 'auth/getUser',
            incidences: "incidences/getItems",
            totalItems: 'incidences/getTotalItems',


        })
    },
    async created() {
        await this.chargeData();


    },
    watch: {
        status() {
            this.currentPage = 1
            this.chargeData()
        },
        zone() {
            this.currentPage = 1
            this.chargeData()
        },
    },
    methods: {
        ...mapActions({

            getIncidences: "incidences/getListIncidences",


        }),
        async chargeData() {

            let pageLength = this.pageLength ? this.pageLength : 1;
            let client = this.currentUser.clients.length > 0 ? this.currentUser.clients[0].id : ''

            console.log('assets', this.asset_id)

            this.getIncidences({
                id_cliente: this.client_id,
                // project: this.project_id,
                // maintenance_id: this.maintenance_id,
                asset_id: this.asset_id,
                page: this.currentPage,
                per_page: pageLength,
                search: this.searchTerm,

            })
        },
        handleSearch() {
            this.currentPage = 1;
            this.chargeData();
        },
        handleChangePage(page) {
            this.currentPage = page;
            this.chargeData();
        },
        handlePageChange() {
            this.currentPage = 1;
            this.chargeData();
        },
        onSortChange(params) {
            this.currentPage = 1;
            console.log(params);
            this.chargeData();
        },

    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

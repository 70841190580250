<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder h2back m-0">
        <span @click="$router.go(-1)">
          <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
            <feather-icon icon="ArrowLeftCircleIcon" size="35" />
          </b-button>
        </span> Ficha de Equipo
      </h2>
    </div>
    <!-- body -->
    <b-row v-if="assetPrepare">
      <b-col cols="12">
        <b-card class="height-card">
          <b-row>
            <b-col cols="12" class="text-right" v-if="!['admin','tec_client'].includes(currentUser.roles[0].name)">
              <b-button v-if="isCurtains"  variant="primary" class="btn-primary" size="sm" v-b-modal.modalIni>
                + Generar archivo INI
              </b-button>
              <b-link :to="{ name: 'editAsset', params: { id: this.$route.params.id } }"
                class="d-inline-block btn-primary ml-2" v-if="currentUser.roles[0].name === 'super_admin'">
                <span class="">Editar</span>
                <span class=" pl-1">
                  <feather-icon icon="Edit2Icon" />
                </span>
              </b-link>
              <hr />
            </b-col>
          </b-row>
          <b-row class="position-relative">
            <b-col xl="10">
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <p v-if="asset.category" class="mb-50 title-style text-uppercase titleCategory">
                    {{ asset.category.name }}
                  </p>
                  <p class="font-weight-bolder titleProduct">
                    {{ asset.name }}
                  </p>
                </div>
                <div>
                  <b-badge class="position-relative" :variant="statusAssetVariants[asset.status.alias]" style="top: -3px">
                    <feather-icon :icon="statusAssetIcons[asset.status.alias]" />
                    {{ asset.status.name }}
                  </b-badge>
                </div>
              </div>
            </b-col>
            <span :class="collapseInfo ? 'text-danger collapse-button' : 'text-danger collapse-button collapsed'"
              :aria-expanded="collapseInfo ? 'true' : 'false'" aria-controls="collapse-info"
              @click="collapseInfo = !collapseInfo">
              <feather-icon :icon="collapseInfo ? 'ChevronUpIcon' : 'ChevronDownIcon'" size="22" />
            </span>
            <b-col cols="12">
              <b-collapse id="collapse-info" v-model="collapseInfo" class="mt-2">
                <hr>
                <b-row>
                  <b-col lg="3" md="12" class="product_info">
                    <div v-if="isCurtains && asset.ini">
                      <p><strong>Código INI:</strong> {{ asset.ini }}</p>
                    </div>
                    <div v-if="asset.client">
                      <p><strong>Cliente:</strong>
                        <b-link :to="{ name: 'viewClient', params: { id: asset.client.id } }"
                          class="link">
                          {{ asset.client.name }}
                        </b-link>
                      </p>
                    </div>
                    <div v-if="asset.project">
                      <p><strong>Obra:</strong>
                        <b-link :to="{ name: 'viewProject', params: { id: asset.project.id } }"
                          class="link">
                          {{ asset.project.name }}
                        </b-link></p>
                    </div>
                    <div v-if="asset.mac_address && isCurtains">
                      <p><strong>Mac_address:</strong>
                        {{ asset.mac_address }}
                      </p>
                    </div>
                  </b-col>
                  <b-col lg="5">
                    <span v-if="asset.documents_asset.length > 0">
                      <span v-for="item in asset.documents_asset">
                        <b-link target="_blank" :href="item.path" class="ml-2">
                          <feather-icon icon="FileIcon" />
                          {{ item.name }}
                        </b-link>
                      </span>
                    </span>
                  </b-col>
                  <b-col lg="4" md="12" class="text-center">
                    <img v-if="asset.product.image_url" :src="asset.product.image_url" :alt="asset.product.name"
                      class="w-50" />
                  </b-col>
                  <b-col>
                    <div v-if="asset.description">
                      <p class="m-0"><strong>Descripción:</strong></p>
                      <div v-html="asset.description"></div>
                    </div>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>

          </b-row>
        </b-card>
      </b-col>

      <b-col v-if="isCurtains" cols="12">
        <!-- tabs -->
        <b-tabs content-class="col-12 mt-1 mt-md-0" nav-wrapper-class="col-12" nav-class="nav-left">
          <!-- mantenimientos -->
          <b-tab active>
            <template #title>
              <feather-icon icon="ToolIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Histórico de Mantenimientos</span>
            </template>

            <maintenances-table :asset_id="asset.id" :project_id="asset.project.id" />
          </b-tab>
          <!--  inicidencias -->
          <b-tab>
            <template #title>
              <feather-icon icon="AlertTriangleIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Inicidencias del equipo</span>
            </template>

            <incidences-table
              :client_id="asset.client.id"
              :asset_id="asset.id"
              :project_id="asset.project.id"
            />
          </b-tab>
          <!-- alertas -->
          <b-tab>
            <template #title>
              <feather-icon icon="AlertCircleIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Alertas</span>
            </template>

            <div>
              <b-card no-body>
                <!-- tabla -->
                <b-table hover responsive :fields="columns" :items="asset.alerts" class="table-white">
                  <template #cell(fecha)="data">
                    <span>{{ dateColumn(data.item.date_timestamp) }}</span>
                  </template>
                  <template #cell(tipo)="data">
                    <span>{{ data.item.tipo.alias }}</span>
                  </template>
                </b-table>
              </b-card>
            </div>

          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>


    <!-- modal ini -->
    <b-modal id="modalIni" title="Generar archivo INI" ok-title="Cancelar" ok-only>
      <!-- form -->
      <validation-observer ref="createIni">
        <b-form class="mt-2" @submit.prevent="handleSubmit" @keydown.enter="handleSubmit">

          <!-- mac_address -->
          <validation-provider #default="{ errors }" :name="'mac_address'" rules="required">
            <b-form-group :label="'mac_address'" label-for="product-mac_address">
              <b-form-input v-model="ini.mac_address" name="mac_address" :placeholder="'mac_address'"
                :disabled="ini.mac_address != null" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- cNumber -->
          <validation-provider #default="{ errors }" :name="'cNumber'" rules="required">
            <b-form-group :label="'cNumber'" label-for="product-name">
              <b-form-input v-model="ini.cNumber" name="cNumber" :placeholder="'cNumber'" disabled />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- milis_STABLE_DEFAULT_INPUT -->
          <validation-provider #default="{ errors }" :name="'milis_STABLE_DEFAULT_INPUT'" rules="required">
            <b-form-group :label="'milis_STABLE_DEFAULT_INPUT'" label-for="milis_STABLE_DEFAULT_INPUT">
              <b-form-input type="number" v-model="ini.milis_STABLE_DEFAULT_INPUT" name="milis_STABLE_DEFAULT_INPUT"
                :placeholder="'milis_STABLE_DEFAULT_INPUT'" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- milis_STABLE_DEFAULT_INPUT -->
          <validation-provider #default="{ errors }" :name="'milis_STABLE_SOBREI'" rules="required">
            <b-form-group :label="'milis_STABLE_SOBREI'" label-for="milis_STABLE_SOBREI">
              <b-form-input type="number" v-model="ini.milis_STABLE_SOBREI" name="milis_STABLE_SOBREI"
                :placeholder="'milis_STABLE_SOBREI'" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- milis_UP_DOWN_TIME -->
          <validation-provider #default="{ errors }" :name="'milis_UP_DOWN_TIME'" rules="required">
            <b-form-group :label="'milis_UP_DOWN_TIME'" label-for="milis_UP_DOWN_TIME">
              <b-form-input type="number" v-model="ini.milis_UP_DOWN_TIME" name="milis_UP_DOWN_TIME"
                :placeholder="'milis_UP_DOWN_TIME'" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- milis_UP_STOP_TIME -->
          <validation-provider #default="{ errors }" :name="'milis_UP_STOP_TIME'" rules="required">
            <b-form-group :label="'milis_UP_STOP_TIME'" label-for="milis_UP_STOP_TIME">
              <b-form-input type="number" v-model="ini.milis_UP_STOP_TIME" name="milis_UP_STOP_TIME"
                :placeholder="'milis_UP_STOP_TIME'" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- milis_DEFAULT_BOTTOM_TIME -->
          <validation-provider #default="{ errors }" :name="'milis_DEFAULT_BOTTOM_TIME'" rules="required">
            <b-form-group :label="'milis_DEFAULT_BOTTOM_TIME'" label-for="milis_DEFAULT_BOTTOM_TIME">
              <b-form-input type="number" v-model="ini.milis_DEFAULT_BOTTOM_TIME" name="milis_DEFAULT_BOTTOM_TIME"
                :placeholder="'milis_DEFAULT_BOTTOM_TIME'" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- milis_ALEXT_DOWN_TIME -->
          <validation-provider #default="{ errors }" :name="'milis_ALEXT_DOWN_TIME'" rules="required">
            <b-form-group :label="'milis_ALEXT_DOWN_TIME'" label-for="milis_ALEXT_DOWN_TIME">
              <b-form-input type="number" v-model="ini.milis_ALEXT_DOWN_TIME" name="milis_ALEXT_DOWN_TIME"
                :placeholder="'milis_ALEXT_DOWN_TIME'" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <validation-provider #default="{ errors }" :name="'milis_ALEXT_PAUSE_TIME'" rules="required">
            <b-form-group :label="'milis_ALEXT_PAUSE_TIME'" label-for="milis_ALEXT_PAUSE_TIME">
              <b-form-input type="number" v-model="ini.milis_ALEXT_PAUSE_TIME" name="milis_ALEXT_PAUSE_TIME"
                :placeholder="'milis_ALEXT_PAUSE_TIME'" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- SSID -->
          <validation-provider #default="{ errors }" :name="'SSID'">
            <b-form-group :label="'SSID'" label-for="SSID">
              <b-form-input v-model="ini.SSID" name="SSID" :placeholder="'SSID'" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <!-- PWD -->
          <validation-provider #default="{ errors }" :name="'PWD'">
            <b-form-group :label="'PWD'" label-for="PWD">
              <b-form-input v-model="ini.PWD" name="PWD" :placeholder="'PWD'" />
            </b-form-group>
            <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <b-button type="submit" variant="primary" class="mt-2 mr-1 text-uppercase">
            {{ 'Generar ini' }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BCollapse, BLink, BButton,
  BTable, BTabs,
  BTab,
  BFormGroup,
  BFormInput,
  BForm, BBadge

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { config } from '@/shared/app.config'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MaintenancesTable from './MaintenancesTable.vue'
import IncidencesTable from './IncidencesTable.vue'
import { TimestampToFlatPickr } from '@/libs/helpers'

export default {
  components: {
    BButton,
    BLink,
    BRow,
    BCol,
    BCard,
    BCollapse,
    BTable,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BForm, BBadge,
    MaintenancesTable,
    IncidencesTable
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sortBy: '',
      sortDesc: '',
      statusProjectsVariant: config.statusProjectsVariant,
      statusProjectsText: config.statusProjectsText,
      statusJobsVariant: config.statusJobsVariants,
      statusJobsText: config.statusJobsText,
      statusAssetVariants: config.statusAssetVariants,
      statusAssetIcons: config.statusAssetIcons,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      collapseInfo: false,
      assetPrepare: false,
      pages: ['10', '15', '25'],
      date_ini: '',
      date_fin: '',
      status: '',
      searchTerm: '',
      visibleFilter: false,
      columns: [
        {
          label: 'Fecha',
          key: 'fecha',
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'Tipo',
          key: 'tipo',
        },
      ]
    }
  },
  watch: {
    sortBy() {
      this.currentPage = 1
      this.chargeData()
    },
    sortDesc() {
      this.currentPage = 1
      this.chargeData()
    },
    date_ini() {
      this.currentPage = 1
      this.chargeData()
    },
    date_fin() {
      this.currentPage = 1
      this.chargeData()
    },
    status() {
      this.currentPage = 1
      this.chargeData()
    },
  },
  computed: {
    ...mapGetters({
      asset: 'assets/getAsset',
      ini: 'assets/getDataIni',
      currentUser: 'auth/getUser',
      selectAlertTypes: 'alerts/getSelectAlertTypes'
    }),
    isCurtains () {
      return this.asset.category_parent.ref === 'cortinas'
    }
  },
  methods: {
    ...mapActions({
      getAsset: 'assets/getAsset',
      getIni: 'assets/getIni',
      setIni: 'assets/setIni',
      getSelectAlertTypes: 'alerts/getSelectAlertTypes'
    }),
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    deleteTask(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: 'Eliminar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(id)
          }
        })
    },
    chargeData() {
      this.getIni(this.$route.params.id)

      if (this.asset) {
        this.assetPrepare = true
      }
    },
    async handleSubmit() {
      this.$refs.createIni.validate().then(success => {
        this.ini['asset_id'] = this.ini.id

        if (success) {
          this.setIni({ ini: this.ini })
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end',
          });
        }
      })
    },
    createFormData() {
      const data = new FormData()
    }
  },
  async created() {
    await this.getAsset(this.$route.params.id)
    await this.chargeData()
    await this.getSelectAlertTypes()
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

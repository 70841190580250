<template>
    <div>
        <b-card no-body>
            <div v-if="currentUser.roles[0].name === 'super_admin'" class="p-1">
                <div class="d-flex align-items-center justify-content-end">
                    <b-link
                        :to="{ name: 'createMaintenance', params: { project_id: project_id, client_id: asset.client.id, asset: asset } }">
                        <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                            {{ '+ Crear mantenimiento' }}
                        </b-button>
                    </b-link>
                </div>
            </div>
            <!-- tabla -->
            <b-table hover responsive :items="maintenances" :fields="columns" class="table-white">
                <template #cell(name)="data">
                    <b-link :to="{ name: 'viewMaintenance', params: { id: data.item.id } }" class="link">
                        {{ data.item.name }}
                    </b-link>
                </template>
                <template #cell(date)="data">
                    <span :class="(data.item.expired) ? 'text-red' : ''">{{ dateColumn(data.item.date) }}</span>
                </template>
                <template #cell(frequency_type)="data">
                    <span>{{ frequencyText[data.item.frequency_type] }}</span>
                </template>
                <template #cell(status)="data">
                    <b-badge :variant="statusMaintenanceVariants[data.item.status.alias]">
                        {{ data.item.status.name }}
                    </b-badge>
                </template>
                <template #cell(user)="data">
                    <b-link v-if="data.item.user" :to="{ name: 'viewUser', params: { id: data.item.user.id } }"
                        class="link">
                        {{ data.item.user.name }} {{ data.item.user.surname }}
                    </b-link>
                </template>
            </b-table>
            <!-- paginador  -->
            <div v-if="totalItems > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
                <div class="d-flex align-items-center mb-0">
                    <span class="text-nowrap ">
                        {{ 'Por página' }}:
                    </span>
                    <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
                    <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
                </div>
                <div>
                    <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number
                        last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                        @change="handleChangePage">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </b-card>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
    BCard,
    BTable,
    BLink,
    BButton,
    BFormSelect,
    BPagination,
    BBadge
} from "bootstrap-vue";

import store from "@/store/index";
import { config } from "@/shared/app.config";
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'

export default {
    components: {
        BTable,
        BCard,
        BLink,
        BButton,
        BFormSelect,
        BPagination,
        BBadge
    },
    props: {
        project_id: {
            type: Number,
            default: 0,
        },
        asset_id: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            currentPage: 1,
            pageLength: 10,
            pages: ['10', '15', '25'],
            searchTerm: '',
            columns: [
                {
                    label: 'Referencia',
                    key: 'ref',
                },
                {
                    label: 'Nombre',
                    key: 'name',
                },
                // {
                //     label: 'Obra',
                //     key: 'project',
                // },
                {
                    label: 'Fecha',
                    key: 'date',
                },
                {
                    label: 'Frecuencia',
                    key: 'frequency_type',
                },
                {
                    label: 'Técnico',
                    key: 'user',
                },
                {
                    label: 'Estado',
                    key: 'status',
                }
            ],
            frequencyText: config.frequencyText,
            statusMaintenanceVariants: config.statusMaintenanceVariants,
        };
    },
    computed: {
        ...mapGetters({
            // client: "clients/getClient",
            currentUser: 'auth/getUser',
            asset: 'assets/getAsset',
            maintenances: 'maintenances/getItems',
            totalItems: 'maintenances/getTotalItems',
            selectStatus: 'orders/getSelectStatus',

        })
    },
    async created() {
        // console.log('cargar asset...', this)
        // await this.getAsset(this.asset_id)
        await this.chargeData();
    },
    methods: {
        ...mapActions({
            // getClient: "clients/getClient",
            getAsset: 'assets/getAsset',
            getListMaintenances: 'maintenances/getListMaintenances',
            getSelectStatus: 'orders/getSelectStatus',
            delete: 'maintenances/delete',
        }),
        dateColumn(time) {
            return TimestampToFlatPickr(time)
        },
        async chargeData() {

            let client = this.currentUser.clients.length > 0 ? this.currentUser.clients[0].id : ''
            console.log('cargando datos', this.asset_id, this)

            this.getListMaintenances({
                page: this.currentPage,
                per_page: this.pageLength,
                search: this.searchTerm,
                // project_id: this.project_id,
                asset_id: this.asset_id,
                id_cliente: client,

            })
        },
        handleSearch() {
            this.currentPage = 1;
            this.chargeData();
        },
        handleChangePage(page) {
            this.currentPage = page;
            this.chargeData();
        },
        handlePageChange() {
            this.currentPage = 1;
            this.chargeData();
        },
        onSortChange(params) {
            this.currentPage = 1;
            console.log(params);
            this.chargeData();
        },
        deleteMaintenance(location, name) {
            this.$bvModal
                .msgBoxConfirm(`¿Estás seguro de eliminar ${name}?`, {
                    bodyClass: 'bodyDelete',
                    footerClass: 'footerDelete',
                    okVariant: 'danger',
                    okTitle: 'Eliminar',
                    cancelTitle: 'Cancelar',
                    cancelVariant: 'outline-danger',
                    centered: true,
                })
                .then(value => {
                    if (value === true) {
                        this.delete(location)
                    }
                })
        },
    },
};
</script>

<style lang="scss">
.text-red {
    color: red !important
}
</style>
